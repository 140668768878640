import React, { FC } from 'react'
import { useTheme } from '../../theme/use-theme'
import { Stack, Text } from 'baselift'
import { ExternalLinks } from './external-links'
import { useI18n, TranslationBundles } from '../../i18n/i18n-context'

const translations: TranslationBundles = {
  'en-US': {
    namespace: 'footer',
    translations: {
      copyright: 'Jonathan Madelaine © {{year}}',
    },
  },
}

export const Footer: FC = ({ ...other }) => {
  const theme = useTheme()
  const { t } = useI18n(translations)

  return (
    <footer
      css={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
      }}
      {...other}
    >
      <Stack direction="column" alignCross="center" css={{ padding: theme.space('m', 'xs') }}>
        <ExternalLinks />
        <Text
          role="contentinfo"
          css={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            fontSize: theme.fontSizes[2],
            marginTop: 'auto',
            color: theme.colors.dark.neutral.medium,
          }}
        >
          {t('footer:copyright', { year: new Date(Date.now()).getFullYear() })}
        </Text>
      </Stack>
    </footer>
  )
}
