import React, { createContext, FC, useContext, useCallback, useEffect } from 'react'
import { TOptions } from 'i18next'
import { useTranslation } from 'react-i18next'
import { SupportedLanguage } from './i18n-instance'
import { DateTime } from 'luxon'

export interface TranslationBundles {
  [key: string]: TranslationBundle
  'en-US': TranslationBundle
}

type Translation = string | { [key: string]: Translation }

export interface TranslationBundle {
  namespace: string
  translations: {
    [key: string]: Translation
  }
}

interface II18nContext {
  t: UnescapedTFunction
  d: (dateTime: DateTime) => DateTime
  language: string
  setLanguage: (l: SupportedLanguage) => void
  addTranslations: (bundles: TranslationBundles) => void
}

type UnescapedTFunction = (
  key: string | string[],
  options?: TOptions<{ [key: string]: any }>
) => string

export const I18nContext = createContext<II18nContext>({} as II18nContext)

export const useI18n = (bundles?: TranslationBundles) => {
  const ctx = useContext(I18nContext)
  if (bundles) {
    ctx.addTranslations(bundles)
  }
  return ctx
}

export const I18nProvider: FC = ({ children }) => {
  const { t: i18nT, i18n } = useTranslation()

  const t: UnescapedTFunction = useCallback(
    (key, options) => {
      const unescapedOptions = {
        ...options,
        interpolation: {
          escapeValue: false,
          ...options?.interpolation,
        },
      }

      return i18nT(key, unescapedOptions)
    },
    [i18nT]
  )

  const addTranslations = (bundles: TranslationBundles) => {
    const fallbackLanguage = i18n.options.fallbackLng as string[]
    const currentLanguages = [i18n.language, ...fallbackLanguage]

    Object.keys(bundles).forEach(lng => {
      if (currentLanguages.includes(lng) && !i18n.hasResourceBundle(lng, bundles[lng].namespace)) {
        i18n.addResourceBundle(lng, bundles[lng].namespace, bundles[lng].translations)
      }
    })
  }

  const setLanguage = (l: SupportedLanguage) => {
    i18n.changeLanguage(l)
  }

  const d = (dateTime: DateTime) => {
    return dateTime
  }

  useEffect(() => {
    i18n.options.saveMissing = true
    i18n.options.missingKeyHandler = (languages, namespace, key, fallback) => {
      const message = `Missing translation for key "${key}". Falling back to value "${fallback}"`
      const error = {
        message,
        data: {
          languages,
          namespace,
          key,
          fallback,
        },
      }
      console.error(error)
    }
  }, [i18n])

  return (
    <I18nContext.Provider
      value={{
        d,
        t,
        language: i18n.language,
        setLanguage,
        addTranslations,
      }}
    >
      {children}
    </I18nContext.Provider>
  )
}
