import React, { FC, createContext, useContext, useState, Dispatch, SetStateAction } from 'react'

interface INavStyleContext {
  isHovering: boolean
  setIsHovering: Dispatch<SetStateAction<boolean>>
  lastHoveredIndex?: number
  setLastHoveredIndex: Dispatch<SetStateAction<number | undefined>>
  linkWidths: number[]
  setLinkWidths: Dispatch<SetStateAction<number[]>>
  isFirstEntry: boolean
  setIsFirstEntry: Dispatch<SetStateAction<boolean>>
}

export const NavStyleContext = createContext({} as INavStyleContext)

export const useNavStyle = () => useContext(NavStyleContext)

export const NavStyleProvider: FC = ({ children }) => {
  const [isHovering, setIsHovering] = useState(false)
  const [lastHoveredIndex, setLastHoveredIndex] = useState<number>()
  const [linkWidths, setLinkWidths] = useState<number[]>([])

  // used to jump underline to hovered link on first entry if no current index exists
  const [isFirstEntry, setIsFirstEntry] = useState<boolean>(true)

  return (
    <NavStyleContext.Provider
      value={{
        isHovering,
        setIsHovering,
        lastHoveredIndex,
        setLastHoveredIndex,
        linkWidths,
        setLinkWidths,
        isFirstEntry,
        setIsFirstEntry,
      }}
    >
      {children}
    </NavStyleContext.Provider>
  )
}
