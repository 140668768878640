interface IColor {
  darkest: string
  dark: string
  mediumDark: string
  medium: string
  mediumLight: string
  light: string
  lightest: string
}

interface INeutralColor {
  black: string
  darkest: string
  darker: string
  dark: string
  mediumDarker: string
  mediumDark: string
  medium: string
  mediumLight: string
  mediumLighter: string
  light: string
  lighter: string
  lightest: string
  white: string
}

interface IColors {
  primary: IColor
  highlight: IColor
  danger: string
  neutral: INeutralColor
}

interface IThemeColors {
  dark: IColors // dark theme
  light: IColors // light theme
}

const spaceScale = {
  xxs: '0.25rem',
  xs: '0.5rem',
  s: '1rem',
  m: '2rem',
  l: '4rem',
  xl: '8rem',
  xxl: '16rem',
} as const

type Space = '' | 0 | '0' | keyof typeof spaceScale | undefined

export interface ITheme {
  colors: IThemeColors
  fontSizes: string[]
  shadows: string[]
  size: (arg: number) => string
  space: (...args: Space[]) => string
}

export const theme: ITheme = {
  colors: {
    // dark theme
    dark: {
      primary: {
        darkest: '#07306B',
        dark: '#0E4F91',
        mediumDark: '#1879BF',
        medium: '#19A3E8',
        mediumLight: '#6AC8F7',
        light: '#A3E3FF',
        lightest: '#DDF5FF',
      },
      neutral: {
        black: '#000000',
        darkest: '#141519', // 1
        darker: '#1B1C21', //  1
        dark: '#25272D', // 10
        mediumDarker: '#3A3D44', // 6
        mediumDark: '#575C66',
        medium: '#848C96', // 8
        mediumLight: '#AEB4BF', // 3
        mediumLighter: '#CBD1DB',
        light: '#DCE2EA', // 7
        lighter: '#E6EBF4',
        lightest: '#EFF4F9', // 4
        white: '#FFFFFF', // 19
      },
      highlight: {
        darkest: '#033D3B',
        dark: '#03604E',
        mediumDark: '#079167',
        medium: '#11AF6D',
        mediumLight: '#3BCE7D',
        light: '#96EAAE',
        lightest: '#D3FFDA',
      },
      danger: '#FF4457',
    },
    // light theme
    light: {
      primary: {
        darkest: '#ff00ff',
        dark: '#ff00ff',
        mediumDark: '#8BD8ED',
        medium: '#BFF4FF',
        mediumLight: '#ff00ff',
        light: '#ff00ff',
        lightest: '#ff00ff',
      },
      neutral: {
        black: '#000000',
        darkest: '#141519', // 1
        darker: '#1B1C21', //  1
        dark: '#25272D', // 10
        mediumDarker: '#3A3D44', // 6
        mediumDark: '#575C66',
        medium: '#848C96', // 8
        mediumLight: '#AEB4BF', // 3
        mediumLighter: '#CBD1DB',
        light: '#DCE2EA', // 7
        lighter: '#E6EBF4',
        lightest: '#EFF4F9', // 4
        white: '#FFFFFF', // 19
      },
      highlight: {
        darkest: '#ff00ff',
        dark: '#ff00ff',
        mediumDark: '#EF4C67',
        medium: '#FF667F',
        mediumLight: '#ff00ff',
        light: '#ff00ff',
        lightest: '#ff00ff',
      },
      danger: '#FF4457',
    },
  },
  fontSizes: [
    '0.5625rem',
    '0.6875rem',
    '0.8125rem',
    '1rem',
    '1.1875rem',
    '1.5rem',
    '1.875rem',
    '2.5rem',
    '3.25rem',
  ],
  shadows: [
    '0 1px 3px #0000001f, 0 1px 2px #0000003d',
    '0 3px 6px #00000029, 0 3px 6px #0000003b',
    '0 10px 20px #00000033, 0 6px 6px #0000003b',
    '0 14px 28px #00000040, 0 10px 10px #00000038',
    '0 19px 38px #0000004d, 0 15px 12px #00000038',
  ],
  size: (arg: number) => {
    return `${arg * 4}rem`
  },
  space: (...args: Space[]) => {
    return args.map(a => spaceScale[a as keyof typeof spaceScale] || '0').join(' ')
  },
}
