import React, { FC } from 'react'
import { Global, InterpolationWithTheme } from '@emotion/core'
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming'
import { theme } from './theme'

const cssReset: InterpolationWithTheme<any> = {
  '*, *::before, *::after': {
    boxSizing: 'inherit',
    margin: 0,
    padding: 0,
    border: 0,
    font: 'inherit',
    verticalAlign: 'baseline',
    outlineColor: 'inherit',
  },
  'strong, b': {
    fontWeight: 'bold',
  },
  'ol, ul': {
    listStyle: 'none',
  },
  'a[class], a[class]:visited, a[class]:hover': {
    textDecoration: 'none',
  },
  'a:not([class])': {
    color: 'inherit',
  },
  table: {
    borderCollapse: 'collapse',
    borderSpacing: 0,
  },
}

const baseStyle: InterpolationWithTheme<any> = {
  html: {
    fontSize: '1rem',
    lineHeight: 1.7,
    fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, sans-serif',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  code: {
    fontFamily: "'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New', monospace",
  },
}

const lightDarkThemeVars: InterpolationWithTheme<any> = {
  'body.light': {
    '--background': theme.colors.light.neutral.white,
    '--background2': theme.colors.light.primary.medium,
    '--color': theme.colors.light.neutral.darkest,
    '--pre-background': theme.colors.light.neutral.lightest,
    '--pre-main': theme.colors.light.neutral.darkest,
    '--pre-comment': theme.colors.light.neutral.medium,
    '--pre-number': '#F76D47',
    '--pre-string': '#91B859',
    '--pre-keyword': '#7C4DFF',
    '--pre-function': '#6182B8',
    '--pre-class': '#FFB62C',
    '--pre-operator': '#39ADB5',
    '--pre-punctuation': '#39ADB5',
    '--code-background': theme.colors.light.neutral.lightest,
    '--social-link--hover': theme.colors.light.highlight.medium,
    '--anchor-text-background': `${theme.colors.light.primary.medium}80`,
    '--anchor-text-background--hover': theme.colors.light.primary.medium,
    '--anchor-text-border': `${theme.colors.light.primary.mediumDark}80`,
    '--anchor-text-border--hover': theme.colors.light.primary.mediumDark,
    '--blog-section-heading-color': theme.colors.light.highlight.mediumDark,
    '--blog-card-background': theme.colors.light.neutral.lightest,
    '--blog-card-background--hover': theme.colors.light.neutral.light,
    '--blog-card-link': theme.colors.light.highlight.medium,
    '--blog-card-link--hover': theme.colors.light.highlight.mediumDark,
    '--contact-network-error-background': theme.colors.light.neutral.lightest,
    '--danger': theme.colors.light.danger,
    '--button-text': theme.colors.light.neutral.white,
    '--button-background': theme.colors.light.highlight.medium,
    '--button-background--hover': theme.colors.light.highlight.mediumDark,
    '--button-background--disabled': theme.colors.light.neutral.light,
    '--input-border': theme.colors.light.neutral.medium,
    '--input-border--hover': theme.colors.light.neutral.dark,
    '--mini-nav-background': `${theme.colors.light.neutral.white}f0`,
    '--nav-text-1': theme.colors.light.neutral.darkest,
    '--nav-text-2': `${theme.colors.light.neutral.darkest}80`,
    '--post-minor-text': theme.colors.light.neutral.mediumDark,
    '--post-body-text': theme.colors.light.neutral.darkest,
    '--scrollbar-background': theme.colors.light.neutral.white,
    '--scrollbar-handle': theme.colors.light.neutral.mediumLighter,
    '--scrollbar-handle--hover': theme.colors.light.neutral.medium,
    '--scrollbar-handle--active': theme.colors.light.neutral.mediumDark,
  },
  'body.dark': {
    '--background': theme.colors.dark.neutral.darker,
    '--color': theme.colors.dark.neutral.white,
    '--background2': theme.colors.dark.neutral.dark,
    '--pre-background': theme.colors.dark.neutral.darkest,
    '--pre-main': theme.colors.dark.neutral.white,
    '--pre-comment': theme.colors.dark.neutral.medium,
    '--pre-number': '#f78c6c',
    '--pre-string': '#c3e88d',
    '--pre-keyword': '#c792ea',
    '--pre-function': '#82aaff',
    '--pre-class': '#ffcb6b',
    '--pre-operator': '#89ddff',
    '--pre-punctuation': '#89ddff',
    '--code-background': theme.colors.dark.neutral.mediumDarker,
    '--social-link--hover': theme.colors.dark.primary.medium,
    '--anchor-text-background': `${theme.colors.dark.primary.mediumDark}60`,
    '--anchor-text-background--hover': theme.colors.dark.primary.mediumDark,
    '--anchor-text-border': `${theme.colors.dark.primary.mediumLight}60`,
    '--anchor-text-border--hover': theme.colors.dark.primary.mediumLight,
    '--blog-section-heading-color': theme.colors.dark.primary.medium,
    '--blog-card-background': theme.colors.dark.neutral.dark,
    '--blog-card-background--hover': theme.colors.dark.neutral.mediumDarker,
    '--blog-card-link': theme.colors.dark.highlight.medium,
    '--blog-card-link--hover': theme.colors.dark.highlight.mediumLight,
    '--contact-network-error-background': theme.colors.dark.neutral.mediumDarker,
    '--danger': theme.colors.dark.danger,
    '--button-text': theme.colors.dark.neutral.white,
    '--button-background': theme.colors.dark.highlight.medium,
    '--button-background--hover': theme.colors.dark.highlight.mediumLight,
    '--button-background--disabled': theme.colors.dark.neutral.medium,
    '--input-border': theme.colors.dark.neutral.medium,
    '--input-border--hover': theme.colors.dark.neutral.light,
    '--mini-nav-background': `${theme.colors.dark.neutral.darker}f0`,
    '--nav-text-1': theme.colors.dark.neutral.white,
    '--nav-text-2': `${theme.colors.dark.neutral.white}80`,
    '--post-minor-text': theme.colors.dark.neutral.mediumLight,
    '--post-body-text': theme.colors.dark.neutral.lightest,
    '--scrollbar-background': theme.colors.dark.neutral.darkest,
    '--scrollbar-handle': theme.colors.dark.neutral.mediumDarker,
    '--scrollbar-handle--hover': theme.colors.dark.neutral.medium,
    '--scrollbar-handle--active': theme.colors.dark.neutral.mediumLight,
  },
}

const scrollbarStyle: InterpolationWithTheme<any> = {
  '::-webkit-scrollbar': {
    width: '12px',
    height: '12px',
  },
  '::-webkit-scrollbar-track': {
    background: 'var(--scrollbar-background)',
  },
  '::-webkit-scrollbar-thumb': {
    background: 'var(--scrollbar-handle)',
    borderRadius: '1000px',
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: 'var(--scrollbar-handle--hover)',
  },
  '::-webkit-scrollbar-thumb:active': {
    background: 'var(--scrollbar-handle--active)',
  },
}

const codeStyle: InterpolationWithTheme<any> = {
  "code[class*='language-'], pre[class*='language-']": {
    color: 'var(--pre-main)',
    textAlign: 'left',
    whiteSpace: 'pre',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    wordWrap: 'normal',
    lineHeight: 1.5,
    MozTabSize: 2,
    OTabSize: 2,
    tabSize: 2,
    WebkitHyphens: 'none',
    MozHyphens: 'none',
    msHyphens: 'none',
    hyphens: 'none',
    transition: 'color 0.5s, background 0.5s',
    // '::selection': {
    //   background: theme.colors.dark.neutral.mediumDarker,
    // },
  },
  pre: {
    overflow: 'auto',
  },

  // Code blocks
  "pre[class*='language-']": {
    background: 'var(--pre-background)',
    borderRadius: '8px',
    padding: theme.space('s', 'm'),
    margin: '0.5rem 0',
    overflow: 'auto',
  },

  // Inline code
  ":not(pre) > code[class*='language-']": {
    borderRadius: '4px',
    backgroundColor: 'var(--code-background)',
    padding: '2px 4px',
    fontSize: theme.fontSizes[3],
    wordWrap: 'break-word',
    // whiteSpace: '-moz-pre-wrap',
    whiteSpace: 'pre-wrap',
  },

  '.token.comment': {
    color: 'var(--pre-comment)',
  },
  '.token.punctuation': {
    color: 'var(--pre-punctuation)',
  },
  '.token.number': {
    color: 'var(--pre-number)',
  },
  '.token.string': {
    color: 'var(--pre-string)',
  },
  '.token.char': {
    color: 'var(--pre-string)',
  },
  '.token.operator': {
    color: 'var(--pre-operator)',
  },
  '.token.keyword': {
    color: 'var(--pre-keyword)',
  },
  '.token.class-name': {
    color: 'var(--pre-class)',
  },
  '.token.function': {
    color: 'var(--pre-function)',
  },
  // '.token::selection': {
  //   background: theme.colors.dark.neutral.white,
  // },
}

export const ThemeProvider: FC = ({ children }) => {
  return (
    <EmotionThemeProvider theme={theme}>
      <>
        <Global styles={cssReset} />
        <Global styles={baseStyle} />
        <Global styles={lightDarkThemeVars} />
        <Global styles={scrollbarStyle} />
        <Global styles={codeStyle} />
        {children}
      </>
    </EmotionThemeProvider>
  )
}
