import React from 'react'
import { ThemeProvider } from './src/theme/theme-provider'
import { I18nProvider } from './src/i18n/i18n-context'
import { DarkModeProvider } from './src/context/dark-mode-context'
import { NavProvider } from './src/context/nav-context'
import { NavStyleProvider } from './src/context/nav-style-context'
import { BreakpointsProvider } from './src/context/breakpoints-context'
import { KeyboardUserProvider } from './src/context/keyboard-user-context'
import './src/i18n/i18n-instance'
import { Layout } from './src/components/shared/layout'

const _importFont = fontUrl => {
  const link = document.createElement('link')
  link.setAttribute('rel', 'stylesheet')
  link.setAttribute('href', fontUrl)
  document.head.appendChild(link)
}

export function onClientEntry() {
  _importFont('https://fonts.googleapis.com/css?family=Inter:300,400,700&display=swap')
  _importFont('https://fonts.googleapis.com/css?family=Source+Code+Pro:400,700&display=swap')
}

// Providers render here
export const wrapRootElement = ({ element }) => (
  <KeyboardUserProvider>
    <DarkModeProvider>
      <ThemeProvider>
        <I18nProvider>
          <BreakpointsProvider
            breakpoints={{
              s: '36em',
              m: '56em',
              l: '76em',
              xl: '106em',
            }}
          >
            <NavProvider>
              <NavStyleProvider>{element}</NavStyleProvider>
            </NavProvider>
          </BreakpointsProvider>
        </I18nProvider>
      </ThemeProvider>
    </DarkModeProvider>
  </KeyboardUserProvider>
)

// Rendering Layout here prevents remounting of header and footer between page loads
export const wrapPageElement = ({ element }) => <Layout>{element}</Layout>
