import React, {
  FC,
  createContext,
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'

interface IDarkModeContext {
  isDarkMode?: boolean
  setIsDarkMode: Dispatch<SetStateAction<boolean | undefined>>
}

export const DarkModeContext = createContext({} as IDarkModeContext)

export const useDarkMode = () => useContext(DarkModeContext)

export const DarkModeProvider: FC = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>()

  useEffect(() => {
    if (window !== undefined && isDarkMode !== undefined) {
      const w = window as any
      w.__setPreferredTheme(isDarkMode ? 'dark' : 'light')
    }
  }, [isDarkMode])

  useEffect(() => {
    if (window !== undefined) {
      const w = window as any
      setIsDarkMode(w.__theme === 'dark')
      w.__onThemeChange = () => {
        setIsDarkMode(w.__theme === 'dark')
      }
    }
  }, [])

  return (
    <DarkModeContext.Provider
      value={{
        isDarkMode,
        setIsDarkMode,
      }}
    >
      {children}
    </DarkModeContext.Provider>
  )
}
