import React, { FC } from 'react'
import { Flex } from 'baselift'
import { useTheme } from '../../theme/use-theme'
import { Header } from '../header/header'
import { Footer } from '../footer/footer'
import { useKeyboardUser } from '../../context/keyboard-user-context'

export const Layout: FC = ({ children, ...other }) => {
  const theme = useTheme()
  const { isKeyboardUser } = useKeyboardUser()

  const headerHeight = theme.size(1)
  const footerHeight = theme.size(3)

  return (
    <Flex
      css={{
        minHeight: '100vh',
        minWidth: '320px',
        flexDirection: 'column',
        color: 'var(--color)',
        background: 'var(--background)',
        outlineColor: isKeyboardUser ? 'var(--color)' : 'transparent',
        transition: 'color 0.5s ease-in-out, background 0.5s ease-in-out',
      }}
    >
      <Header
        css={{
          zIndex: 3,
          height: headerHeight,
        }}
      />
      <main
        css={{
          zIndex: 1,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          minHeight: '100vh',
          paddingTop: headerHeight,
          paddingBottom: footerHeight,
        }}
        {...other}
      >
        <div
          aria-hidden="true"
          tabIndex={-1}
          id="main-content"
          css={{
            height: 0,
            margin: 0,
            padding: 0,
          }}
        />
        {children}
      </main>
      <Footer
        css={{
          zIndex: 2,
          height: footerHeight,
        }}
      />
    </Flex>
  )
}
