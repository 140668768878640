import React, { FC, createContext, useContext, useState, useEffect } from 'react'

interface IKeyboardUserContext {
  isKeyboardUser: boolean
}

export const KeyboardUserContext = createContext({} as IKeyboardUserContext)

export const useKeyboardUser = () => useContext(KeyboardUserContext)

export const KeyboardUserProvider: FC = ({ children }) => {
  const [isKeyboardUser, setIsKeyboardUser] = useState<boolean>(false)

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Tab key
      if (e.keyCode === 9) {
        setIsKeyboardUser(true)
      }
    }

    const handleMouseMove = () => {
      setIsKeyboardUser(false)
    }

    if (window !== undefined) {
      window.addEventListener('keydown', handleKeyDown)
      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('touchstart', handleMouseMove)
    }
    return () => {
      if (window !== undefined) {
        window.removeEventListener('keydown', handleKeyDown)
        window.removeEventListener('mousemove', handleMouseMove)
        window.removeEventListener('touchstart', handleMouseMove)
      }
    }
  }, [])

  return (
    <KeyboardUserContext.Provider
      value={{
        isKeyboardUser,
      }}
    >
      {children}
    </KeyboardUserContext.Provider>
  )
}
