import React, { FC, useEffect } from 'react'
import { useTheme } from '../../theme/use-theme'
import { Box, Flex } from 'baselift'
import { Link } from 'gatsby'
import { useNav } from '../../context/nav-context'
import { useNavStyle } from '../../context/nav-style-context'
import { useBreakpoints } from '../../context/breakpoints-context'
import { Link as ScrollLink, animateScroll, scroller } from 'react-scroll'
import { globalHistory } from '@reach/router'
import { useKeyboardUser } from '../../context/keyboard-user-context'

const paddingX = 16
const paddingY = 8

export const Nav: FC = ({ ...other }) => {
  const theme = useTheme()
  const { bp } = useBreakpoints()

  const {
    isHovering,
    setIsHovering,
    lastHoveredIndex,
    setLastHoveredIndex,
    linkWidths,
    setLinkWidths,
    isFirstEntry,
    setIsFirstEntry,
  } = useNavStyle()

  const { links, activeLinkIndex, setActiveLinkIndex } = useNav()

  const { isKeyboardUser } = useKeyboardUser()

  const getLinkWidths = (ref: HTMLAnchorElement | null) => {
    if (ref) {
      setLinkWidths(prev =>
        prev.length === links.length
          ? prev
          : [...prev, ref.getBoundingClientRect().width - paddingX * 2]
      )
    }
  }

  useEffect(() => {
    setIsFirstEntry(!isHovering)
  }, [isHovering, setIsFirstEntry])

  return (
    <nav
      css={bp({
        position: 'relative',
        display: ['none', 'flex'],
      })}
      onMouseLeave={() => {
        setIsHovering(false)
        if (activeLinkIndex !== undefined) {
          setLastHoveredIndex(activeLinkIndex)
        }
      }}
      onBlur={() => {
        setIsHovering(false)
        if (activeLinkIndex !== undefined) {
          setLastHoveredIndex(activeLinkIndex)
        }
      }}
      {...other}
    >
      <Box
        css={{
          position: 'absolute',
          height: '2px',
          bottom: paddingY,
          left: `${
            activeLinkIndex !== undefined
              ? linkWidths
                  .slice(0, activeLinkIndex)
                  .reduce((a, b) => a + b + paddingX * 2, paddingX * 2) -
                paddingX +
                linkWidths[activeLinkIndex] / 2
              : lastHoveredIndex !== undefined
              ? linkWidths
                  .slice(0, lastHoveredIndex)
                  .reduce((a, b) => a + b + paddingX * 2, paddingX * 2) -
                paddingX +
                linkWidths[lastHoveredIndex] / 2
              : 0
          }px`,
          background: 'var(--nav-text-2)',
          width: `${activeLinkIndex !== undefined ? linkWidths[activeLinkIndex] : 0}px`,
          transition: `left ${
            activeLinkIndex !== undefined ? 0.375 : 0
          }s ease-out, width 0.375s ease-out, background 0.2s`,
          transform: `translate3d(-50%, 0, 0)`,
        }}
      />
      <Box
        css={{
          position: 'absolute',
          height: '2px',
          bottom: paddingY,
          left: `${
            activeLinkIndex !== undefined && !isHovering
              ? linkWidths
                  .slice(0, activeLinkIndex)
                  .reduce((a, b) => a + b + paddingX * 2, paddingX * 2) -
                paddingX +
                linkWidths[activeLinkIndex] / 2
              : lastHoveredIndex !== undefined
              ? linkWidths
                  .slice(0, lastHoveredIndex)
                  .reduce((a, b) => a + b + paddingX * 2, paddingX * 2) -
                paddingX +
                linkWidths[lastHoveredIndex] / 2
              : 0
          }px`,
          background: 'var(--nav-text-1)',
          width: `${
            isHovering && lastHoveredIndex !== undefined
              ? linkWidths[lastHoveredIndex]
              : activeLinkIndex !== undefined
              ? linkWidths[activeLinkIndex]
              : 0
          }px`,
          transition: `left ${
            activeLinkIndex !== undefined || !isFirstEntry ? 0.375 : 0
          }s ease-out, width 0.375s ease-out, background 0.2s`,
          transform: `translate3d(-50%, 0, 0)`,
        }}
      />
      {links.map((l, i) => {
        const baseProps: any = {
          css: {
            padding: `${paddingY}px ${paddingX}px`,
            whiteSpace: 'nowrap',
            cursor: 'pointer',
            color: isHovering && i !== lastHoveredIndex ? 'var(--nav-text-2)' : 'var(--nav-text-1)',
            textTransform: 'uppercase',
            transition: `color 0.375s ease-out`,
            fontWeight: 'bold',
            letterSpacing: '1px',
          },
          onMouseEnter: () => {
            setLastHoveredIndex(i)
            setIsHovering(true)
          },
          onFocus: () => {
            setLastHoveredIndex(i)
            setIsHovering(true)
          },
        }

        return (
          <Flex ref={getLinkWidths as any} key={`${l.pathname}${l.hash ? `#${l.hash}` : ''}`}>
            {globalHistory.location.pathname === l.pathname ? (
              <ScrollLink
                tabIndex={0}
                href=""
                to={l.hash ?? ''}
                onClick={() => {
                  setActiveLinkIndex(i)

                  // only set focus if keyboard user
                  if (window !== undefined && isKeyboardUser) {
                    const el = window.document.getElementById(l.hash ?? 'main-content')
                    if (el) {
                      el.focus()
                    }
                  }

                  // only smooth scroll if not keyboard user,
                  // as we set focus when keyboard user, which messes with smooth scroll
                  if (!isKeyboardUser) {
                    if (l.hash) {
                      scroller.scrollTo(l.hash, { duration: 500, smooth: true })
                    } else {
                      animateScroll.scrollTo(0, { duration: 500, smooth: true })
                    }
                  }
                }}
                {...baseProps}
              >
                {l.label}
              </ScrollLink>
            ) : (
              <Link
                to={`${l.pathname}${l.hash ? `#${l.hash}` : ''}`}
                onClick={() => {
                  setActiveLinkIndex(i)
                  if (window !== undefined) {
                    const gfw = window.document.getElementById('gatsby-focus-wrapper')
                    if (gfw) {
                      gfw.focus()
                    }
                  }
                }}
                {...baseProps}
              >
                {l.label}
              </Link>
            )}
          </Flex>
        )
      })}
    </nav>
  )
}
