import React, { FC, useState, useEffect } from 'react'
import { useTheme } from '../../theme/use-theme'
import { Button, Box } from 'baselift'
import { Link } from 'gatsby'
import { MenuIcon, CloseIcon } from '../../theme/icons'
import { useNav } from '../../context/nav-context'
import { useBreakpoints } from '../../context/breakpoints-context'

export const MiniNav: FC = ({ ...other }) => {
  const theme = useTheme()
  const { bp } = useBreakpoints()

  const { links, setActiveLinkIndex, isMiniNavVisible, setIsMiniNavVisible } = useNav()

  const [fadeMs, setfadeMs] = useState<number>(0)

  useEffect(() => {
    setfadeMs(isMiniNavVisible ? 0.5 : 0)
  }, [isMiniNavVisible, setfadeMs])

  return isMiniNavVisible ? (
    <>
      <Box
        css={bp({
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          height: '100vh',
          width: '100vw',
          cursor: 'pointer',
          background: 'var(--mini-nav-background)',
          transition: `opacity ${fadeMs}s ease-out`,
          backdropFilter: 'blur(8px)',
          opacity: fadeMs ? 1 : 0,
          display: ['block', 'none'],
        })}
        onClick={() => setIsMiniNavVisible(false)}
        {...other}
      />
      <Button
        role="button"
        aria-label="Close navigation"
        css={bp({
          color: 'inherit',
          position: 'absolute',
          width: '48px',
          height: '48px',
          top: '8px',
          right: theme.space('xs'),
          zIndex: 1,
          display: ['block', 'none'],
        })}
        onClick={() => setIsMiniNavVisible(false)}
      >
        <CloseIcon
          css={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '32px',
            height: '32px',
          }}
        />
      </Button>
      <nav
        css={bp({
          position: 'fixed',
          top: theme.size(1),
          left: 0,
          flexDirection: 'column',
          display: ['flex', 'none'],
        })}
      >
        {links.map((l, i) => (
          <Link
            key={`${l.pathname}${l.hash ? `#${l.hash}` : ''}`}
            to={`${l.pathname}${l.hash ? `#${l.hash}` : ''}`}
            onClick={() => {
              setActiveLinkIndex(i)
              setIsMiniNavVisible(false)
            }}
            css={{
              color: 'inherit',
              display: 'inline-block',
              position: 'relative',
              transform: `translate(${fadeMs ? 0 : `-${100 + 33 * i}%`}, 0)`,
              height: '100%',
              whiteSpace: 'nowrap',
              fontSize: theme.fontSizes[4],
              fontWeight: 'bold',
              letterSpacing: '1px',
              padding: theme.space('xs', 's'),
              '&:hover': {
                color: theme.colors.dark.primary.mediumLight,
              },
              transition: `color 0.2s, transform ${0.25 + i * 0.0825}s`,
            }}
          >
            {l.label}
          </Link>
        ))}
      </nav>
    </>
  ) : (
    <Button
      role="button"
      aria-label="Open navigation"
      css={bp({
        color: 'inherit',
        position: 'absolute',
        width: '48px',
        height: '48px',
        top: '8px',
        right: theme.space('xs'),
        zIndex: 1,
        display: ['block', 'none'],
      })}
      onClick={() => setIsMiniNavVisible(true)}
      {...other}
    >
      <MenuIcon
        css={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '32px',
          height: '32px',
        }}
      />
    </Button>
  )
}
