import React, { FC } from 'react'
import { useTheme } from '../../theme/use-theme'
import { Flex, Button } from 'baselift'
import { Nav } from './nav'
import { MiniNav } from './mini-nav'
import { useBreakpoints } from '../../context/breakpoints-context'
import { Link } from 'gatsby'
import { MyIcon, MoonIcon, SunIcon } from '../../theme/icons'
import { useDarkMode } from '../../context/dark-mode-context'
import { useI18n, TranslationBundles } from '../../i18n/i18n-context'

const translations: TranslationBundles = {
  'en-US': {
    namespace: 'header',
    translations: {
      skip_to_content: 'Skip to content',
    },
  },
}

export const Header: FC = ({ ...other }) => {
  const { t } = useI18n(translations)
  const theme = useTheme()
  const { bp } = useBreakpoints()
  const { isDarkMode, setIsDarkMode } = useDarkMode()

  return (
    <header
      css={{
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        background: 'var(--background2)',
        transition: 'background 0.5s ease-in-out',
      }}
      {...other}
    >
      <Flex
        css={bp({
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
          width: '100%',
          maxWidth: theme.size(24),
          margin: 'auto',
          paddingLeft: [0, theme.space('s')],
          paddingRight: [0, theme.space('s')],
        })}
      >
        <a
          href="#main-content"
          css={{
            position: 'absolute',
            top: '-100%',
            left: '-100%',
            background: theme.colors.light.neutral.white,
            color: theme.colors.light.neutral.darkest,
            borderRadius: '4px',
            padding: theme.space('xs', 's'),
            '&:focus': {
              top: theme.space('s'),
              left: theme.space('s'),
            },
          }}
          onClick={() => {
            if (window !== undefined) {
              const el = window.document.getElementById('main-content')
              if (el) {
                el.focus()
              }
            }
          }}
        >
          {t('header:skip_to_content')}
        </a>
        <Link
          aria-label="Home"
          to="/"
          css={{
            height: `calc(32px + ${theme.space('xs')} * 2)`,
            width: `calc(48px + ${theme.space('s')} * 2)`,
            padding: theme.space('xs', 's'),
            color: 'inherit',
          }}
        >
          <MyIcon
            aria-hidden="true"
            css={{
              width: '100%',
              height: '100%',
            }}
          />
        </Link>
        <Flex
          css={{
            height: '100%',
            alignItems: 'center',
          }}
        >
          <Button
            role="button"
            aria-pressed={isDarkMode ? 'true' : 'false'}
            aria-label="Toggle dark mode"
            onClick={() => setIsDarkMode(!isDarkMode)}
            css={bp({
              width: `calc(20px + ${theme.space('xs')} * 2)`,
              height: `calc(20px + ${theme.space('xs')} * 2)`,
              // 48px for mini nav button width
              marginRight: [`calc(48px + ${theme.space('s')})`, theme.space('s')],
            })}
          >
            {isDarkMode === undefined ? null : isDarkMode ? (
              <MoonIcon
                aria-hidden="true"
                css={{
                  height: '20px',
                  width: '20px',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            ) : (
              <SunIcon
                aria-hidden="true"
                css={{
                  height: '20px',
                  width: '20px',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            )}
          </Button>
          <Nav />
          <MiniNav />
        </Flex>
      </Flex>
    </header>
  )
}
