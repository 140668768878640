import React, { FC, createContext, useContext, useState } from 'react'
import facepaint from 'facepaint'

interface IBreakpoints {
  [key: string]: string
}

const defaultBreakpoints: IBreakpoints = {
  s: '600px',
  m: '960px',
  l: '1280px',
  xl: '1920px',
}

interface IBreakpointsContext {
  bp: facepaint.DynamicStyleFunction
}

export const BreakpointsContext = createContext({} as IBreakpointsContext)

export const useBreakpoints = () => useContext(BreakpointsContext)

interface IBreakpointsProviderProps {
  breakpoints?: IBreakpoints
}

export const BreakpointsProvider: FC<IBreakpointsProviderProps> = ({ children, breakpoints }) => {
  const [bp] = useState<IBreakpoints>(breakpoints || defaultBreakpoints)

  const getMediaQueries = () => Object.values(bp).map(b => `@media(min-width: ${b})`)
  const facepaintBreakpoints = facepaint(getMediaQueries())

  return (
    <BreakpointsContext.Provider
      value={{
        bp: facepaintBreakpoints,
      }}
    >
      {children}
    </BreakpointsContext.Provider>
  )
}
