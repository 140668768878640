import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  'en-US': {},
  'ja-JP': {},
}

export type SupportedLanguage = keyof typeof resources

i18next.use(initReactI18next).init({
  resources,
  lng: 'en-US',
  fallbackLng: 'en-US',
})
