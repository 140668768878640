import React, { FC, ComponentProps } from 'react'
import { useTheme } from '../../theme/use-theme'
import { Text, Stack, Icon } from 'baselift'
import { useStaticQuery, graphql } from 'gatsby'
import { useBreakpoints } from '../../context/breakpoints-context'

interface IExternalLink {
  label?: string
  url?: string
  iconData?: any
}

interface IExternalLinksProps extends ComponentProps<typeof Stack> {
  textPosition?: 'bottom' | 'left'
  showFirst?: number
}

export const ExternalLinks: FC<IExternalLinksProps> = ({ textPosition, showFirst, ...other }) => {
  const links = useStaticQuery<any>(
    graphql`
      query AllContentfulJmSocialLinks {
        allContentfulJmSocialLink(sort: { fields: [order], order: ASC }) {
          nodes {
            label
            url
            iconData {
              paths
              viewbox
            }
          }
        }
      }
    `
  ).allContentfulJmSocialLink.nodes as IExternalLink[]

  return (
    <Stack {...other}>
      {links.slice(0, showFirst || links.length).map((l, i) => (
        <ExternalLink key={l.label ?? i} data={l} textPosition={textPosition} />
      ))}
    </Stack>
  )
}

interface IExternalLinkProps {
  data: IExternalLink
  textPosition?: 'bottom' | 'left'
}

const ExternalLink: FC<IExternalLinkProps> = ({
  textPosition = 'bottom',
  data: { label, url, iconData },
}) => {
  const theme = useTheme()
  const { bp } = useBreakpoints()

  return (
    <a
      aria-label={label}
      href={url}
      css={bp({
        color: 'inherit',
        display: 'flex',
        position: 'relative',
        boxSizing: 'border-box',
        flexDirection: 'column',
        padding: [theme.space('xs'), theme.space('xs', 's')],
        cursor: 'pointer',
        overflow: 'hidden',
        userSelect: 'none',
        '&:hover > svg': {
          fill: 'var(--social-link--hover)',
        },
        '&:focus > svg': {
          fill: 'var(--social-link--hover)',
        },
        '&:hover': {
          overflow: 'visible',
        },
        '&:focus': {
          overflow: 'visible',
        },
      })}
    >
      <Icon
        aria-hidden="true"
        css={bp({
          width: [20, 24],
          transition: 'fill 0.2s',
        })}
        viewBox={iconData.viewbox}
        pathDef={iconData.paths}
      />
      <Text
        css={bp({
          padding: theme.space(0, 'xs'),
          position: 'absolute',
          display: 'inline-block',
          visibility: 'hidden',
          bottom: textPosition === 'left' ? '50%' : 0,
          color: 'transparent',
          left: textPosition === 'left' ? 0 : '50%',
          fontSize: [theme.fontSizes[2], theme.fontSizes[3]],
          transition: 'color 0',
          transform: textPosition === 'left' ? 'translate(-100%, 50%)' : 'translate(-50%, 100%)',
          whiteSpace: 'nowrap',
          'a:hover &': {
            color: 'inherit',
            transition: 'color 0.5s',
            visibility: 'visible',
          },
          'a:focus &': {
            color: 'inherit',
            transition: 'color 0.5s',
            visibility: 'visible',
          },
        })}
      >
        {label}
      </Text>
    </a>
  )
}
