import React, {
  FC,
  createContext,
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useMemo,
  useRef,
} from 'react'
import { globalHistory } from '@reach/router'

interface ILink {
  label: string
  pathname: string
  hash?: string
}

const getActiveLinkIndex = (links: ILink[], pathname: string): number | undefined => {
  if (window !== undefined) {
    const yOffset = window.pageYOffset
    const subsetOfLinks = links.filter(l => l.pathname === pathname)

    if (!subsetOfLinks.length) {
      return undefined
    }

    const linksWithTops: { link: ILink; d: number }[] = []
    subsetOfLinks.forEach(l => {
      const anchor = l.hash && window.document.getElementById(l.hash)
      linksWithTops.push({
        link: l,
        d: anchor
          ? Math.abs(anchor.getBoundingClientRect().top)
          : l.hash
          ? Number.MAX_VALUE
          : yOffset,
      })
    })

    const closestLink = linksWithTops.sort((a, b) => a.d - b.d)[0].link
    if (closestLink) {
      return links.indexOf(closestLink)
    }
  }
}

interface INavContext {
  links: ILink[]
  isMiniNavVisible: boolean
  setIsMiniNavVisible: Dispatch<SetStateAction<boolean>>
  activeLinkIndex?: number
  setActiveLinkIndex: (i?: number) => void
}

export const NavContext = createContext({} as INavContext)

export const useNav = () => useContext(NavContext)

export const NavProvider: FC = ({ children }) => {
  const [isMiniNavVisible, setIsMiniNavVisible] = useState(false)
  const [activeLinkIndex, setActiveLinkIndex] = useState<number>()

  // Used to create a grace period after smooth scrolling from nav, so that this component
  // doesn't try to calculate the new active index based on current scroll position
  const scrollGracePeriodRef = useRef<boolean>(false)
  const scrollGracePeriodTimeoutRef = useRef<NodeJS.Timeout>()

  const links: ILink[] = useMemo(
    () => [
      {
        label: 'Home',
        pathname: '/',
      },
      {
        label: 'About',
        pathname: '/',
        hash: 'about',
      },
      {
        label: 'Contact',
        pathname: '/',
        hash: 'contact',
      },
      {
        label: 'Blog',
        pathname: '/blog',
      },
    ],
    []
  )

  // Set current anchor link from scroll
  useEffect(() => {
    setActiveLinkIndex(getActiveLinkIndex(links, globalHistory.location.pathname))

    const onScroll = () => {
      // Only set the anchor from scroll position if grace period for smooth scroll navigation has ended
      if (!scrollGracePeriodRef.current) {
        setActiveLinkIndex(getActiveLinkIndex(links, globalHistory.location.pathname))
      }
    }

    if (window !== undefined) {
      window.addEventListener('scroll', onScroll)
    }
    return () => {
      if (window !== undefined) {
        window.removeEventListener('scroll', onScroll)
      }
    }
  }, [links])

  // hacky code that cancels the grace period after auto scrolling if user actively scrolls
  useEffect(() => {
    const resetScrollDelay = () => {
      if (scrollGracePeriodRef && scrollGracePeriodRef.current) {
        scrollGracePeriodRef.current = false
      }
    }

    if (window !== undefined) {
      window.addEventListener('mousewheel', resetScrollDelay)
      window.addEventListener('touchmove', resetScrollDelay)
    }

    return () => {
      if (window !== undefined) {
        window.removeEventListener('mousewheel', resetScrollDelay)
        window.removeEventListener('touchmove', resetScrollDelay)
      }
    }
  }, [scrollGracePeriodRef])

  return (
    <NavContext.Provider
      value={{
        links,
        isMiniNavVisible,
        setIsMiniNavVisible,
        activeLinkIndex,
        setActiveLinkIndex: i => {
          setActiveLinkIndex(i)
          // Starts a 550ms grace period after current link index is set from external source
          // allows smooth scroll from nav
          scrollGracePeriodRef.current = true
          if (scrollGracePeriodTimeoutRef.current) {
            clearTimeout(scrollGracePeriodTimeoutRef.current)
          }
          scrollGracePeriodTimeoutRef.current = setTimeout(() => {
            if (scrollGracePeriodRef && scrollGracePeriodRef.current) {
              scrollGracePeriodRef.current = false
            }
          }, 550)
        },
      }}
    >
      {children}
    </NavContext.Provider>
  )
}
