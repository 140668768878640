import React, { FC } from 'react'
import { Icon } from 'baselift'

// Special icons

export const MyIcon: FC = props => (
  <Icon
    viewBox="0 0 12 8"
    pathDef={[
      'M 5,0 A 2,2 0 0 0 3,2 V 6 A 1,1 0 0 1 2,7 1,1 0 0 1 1,6 V 4 H 0 V 6 A 2,2 0 0 0 2,8 2,2 0 0 0 4,6 V 3.7285156 2 A 1,1 0 0 1 5,1 1,1 0 0 1 6,2 V 3.7304688 5 H 7 V 3.7285156 2 A 1,1 0 0 1 8,1 3,3 0 0 1 11,4 3,3 0 0 1 8,7 H 6 V 8 H 8 A 4,4 0 0 0 12,4 4,4 0 0 0 8,0 2,2 0 0 0 6.5,0.6796875 2,2 0 0 0 5,0 Z',
    ]}
    {...props}
  />
)

// Generic icons

export const MenuIcon: FC = props => (
  <Icon
    viewBox="0 0 24 24"
    pathDef={[
      'm 4,11 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 16 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z',
      'M 4,5 A 1,1 0 0 0 3,6 1,1 0 0 0 4,7 H 20 A 1,1 0 0 0 21,6 1,1 0 0 0 20,5 Z',
      'm 4,17 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 16 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z',
    ]}
    {...props}
  />
)

export const CloseIcon: FC = props => (
  <Icon
    viewBox="0 0 24 24"
    pathDef={[
      'm 21.191823,2.8090034 a 1,1 0 0 0 -0.05939,-0.053862 1,1 0 0 0 -0.04419,-0.035908 1,1 0 0 0 -0.04005,-0.029002 1,1 0 0 0 -0.01105,-0.00829 1,1 0 0 0 -0.06077,-0.035907 1,1 0 0 0 -1.196005,0.1546797 1,1 0 0 0 -0.0055,0.00552 L 11.996673,10.584416 4.2184983,2.8062412 a 1,1 0 0 0 -0.00276,-0.00276 1,1 0 0 0 -0.1353445,-0.1104855 1,1 0 0 0 -1.270583,0.1077237 1,1 0 0 0 -0.1353439,1.2567711 1,1 0 0 0 0.00552,0.011049 1,1 0 0 0 0.062148,0.081483 1,1 0 0 0 0.052481,0.060767 1,1 0 0 0 0.01243,0.01243 L 10.582459,11.99863 2.8042849,19.776804 a 1,1 0 0 0 -0.2872622,0.795495 1,1 0 0 0 0.01381,0.09944 1,1 0 0 0 0.051099,0.178158 1,1 0 0 0 0.00552,0.01381 1,1 0 0 0 0.00414,0.0069 1,1 0 0 0 0.03867,0.08286 1,1 0 0 0 0.044194,0.07734 1,1 0 0 0 0.00552,0.0083 1,1 0 0 0 0.00138,0.0014 1,1 0 0 0 0.059386,0.07872 1,1 0 0 0 0.063529,0.07182 1,1 0 0 0 0.00276,0.0028 1,1 0 0 0 0.051099,0.04834 1,1 0 0 0 0.019335,0.01657 1,1 0 0 0 0.020716,0.01519 1,1 0 0 0 0.059386,0.04281 1,1 0 0 0 0.00138,0.0014 1,1 0 0 0 0.00829,0.0055 1,1 0 0 0 0.077341,0.04419 1,1 0 0 0 0.089769,0.04281 1,1 0 0 0 0.00138,0.0014 1,1 0 0 0 0.095293,0.03176 1,1 0 0 0 0.095294,0.02348 1,1 0 0 0 0.099437,0.01381 1,1 0 0 0 0.7954951,-0.287262 1,1 0 0 0 0.058005,-0.06353 l 7.7174072,-7.717407 7.768507,7.768507 a 1,1 0 0 0 0.01243,0.01243 1,1 0 0 0 0.140869,0.11601 1,1 0 0 0 0.07458,0.04696 1,1 0 0 0 0.100818,0.04834 1,1 0 0 0 0.08148,0.03177 1,1 0 0 0 1.016466,-0.243068 1,1 0 0 0 0.106343,-1.285774 1,1 0 0 0 -0.04005,-0.05386 1,1 0 0 0 -0.0055,-0.0055 1,1 0 0 0 -0.06077,-0.06905 1,1 0 0 0 -0.0028,-0.0028 L 13.410887,11.99863 21.142105,4.2674113 a 1,1 0 0 0 0.04972,-0.044194 1,1 0 0 0 0.113247,-1.276107 1,1 0 0 0 -0.113247,-0.1381065 z',
    ]}
    {...props}
  />
)

// export const ArrowUpIcon: FC = props => (
//   <Icon
//     viewBox="0 0 24 24"
//     pathDef={[
//       'm 3.2363728,11.76367 a 1,1 0 0 0 0.29297,0.69336 1,1 0 0 0 1.41406,0 l 6.0703102,-6.07031 0,13.61328 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 l 0,-13.61328 6.07031,6.07031 a 1,1 0 0 0 1.41407,0 1,1 0 0 0 0,-1.41406 l -7.07032,-7.07031 -0.70703,-0.70703 a 1,1 0 0 0 -1.41406,0 l -0.70703,0.70703 -7.0703102,7.07031 a 1,1 0 0 0 -0.29297,0.7207 z',
//     ]}
//     {...props}
//   />
// )

export const ArrowRightIcon: FC = props => (
  <Icon
    viewBox="0 0 24 24"
    pathDef={[
      'm 12.236333,3.2091835 a 1,1 0 0 0 -0.69336,0.29297 1,1 0 0 0 0,1.41406 l 6.07031,6.0703105 -13.6132802,0 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 l 13.6132802,0 -6.07031,6.07031 a 1,1 0 0 0 0,1.41407 1,1 0 0 0 1.41406,0 l 7.07031,-7.07032 0.70703,-0.70703 a 1,1 0 0 0 0,-1.41406 l -0.70703,-0.70703 -7.07031,-7.0703105 a 1,1 0 0 0 -0.7207,-0.29297 z',
    ]}
    {...props}
  />
)

// export const ArrowDownIcon: FC = props => (
//   <Icon
//     viewBox="0 0 24 24"
//     pathDef={[
//       'm 20.790819,12.209144 a 1,1 0 0 0 -0.29297,-0.69336 1,1 0 0 0 -1.41406,0 l -6.07031,6.07031 V 3.9728136 a 1,1 0 0 0 -1,-1 1,1 0 0 0 -1,1 V 17.586094 l -6.0703099,-6.07031 a 1,1 0 0 0 -1.41407,0 1,1 0 0 0 0,1.41406 l 7.0703199,7.07031 0.70703,0.70703 a 1,1 0 0 0 1.41406,0 l 0.70703,-0.70703 7.07031,-7.07031 a 1,1 0 0 0 0.29297,-0.7207 z',
//     ]}
//     {...props}
//   />
// )

// export const ArrowLeftIcon: FC = props => (
//   <Icon
//     viewBox="0 0 24 24"
//     pathDef={[
//       'm 11.812345,20.764149 a 1,1 0 0 0 0.692641,-0.294666 1,1 0 0 0 -0.0035,-1.414056 L 6.4163736,12.999994 20.029613,12.966671 a 1,1 0 0 0 0.997549,-1.002445 1,1 0 0 0 -1.002445,-0.997549 L 6.4114779,11 12.46691,4.914849 a 1,1 0 0 0 -0.0035,-1.4140658 1,1 0 0 0 -1.414056,0.00346 L 3.9964116,10.59185 3.2911144,11.300609 a 1,1 0 0 0 0.00346,1.414056 l 0.7087586,0.705297 7.0875956,7.052982 a 1,1 0 0 0 0.721415,0.291205 z',
//     ]}
//     {...props}
//   />
// )

export const ErrorIcon: FC = props => (
  <Icon
    viewBox="0 0 24 24"
    pathDef={[
      'M 12 0 A 12 12 0 0 0 0 12 A 12 12 0 0 0 12 24 A 12 12 0 0 0 24 12 A 12 12 0 0 0 12 0 z M 12 2 A 10.000762 10.000762 0 0 1 22 12 A 10.000762 10.000762 0 0 1 12 22 A 10.000762 10.000762 0 0 1 2 12 A 10.000762 10.000762 0 0 1 12 2 z ',
      'M 12 6 A 1 1 0 0 0 11 7 L 11 8 L 11 10 L 11 13 A 1 1 0 0 0 12 14 A 1 1 0 0 0 13 13 L 13 10 L 13 8 L 13 7 A 1 1 0 0 0 12 6 z M 12 16 A 1 1 0 0 0 11 17 A 1 1 0 0 0 12 18 A 1 1 0 0 0 13 17 A 1 1 0 0 0 12 16 z ',
    ]}
    {...props}
  />
)

export const SunIcon: FC = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <circle cx="5" cy="5" r="2" />
    <circle cx="19" cy="5" r="2" />
    <circle cx="19" cy="19" r="2" />
    <circle cx="5" cy="19" r="2" />
    <circle cx="12" cy="2" r="2" />
    <circle cx="12" cy="22" r="2" />
    <circle cx="12" cy="12" r="6" />
    <circle cx="2" cy="12" r="2" />
    <circle cx="22" cy="12" r="2" />
  </Icon>
)

export const MoonIcon: FC = props => (
  <Icon
    viewBox="0 0 24 24"
    pathDef={[
      'M 8.5273438 0.51953125 A 12 12 0 0 0 3.515625 3.515625 A 12 12 0 0 0 3.515625 20.484375 A 12 12 0 0 0 20.484375 20.484375 A 12 12 0 0 0 23.472656 15.480469 A 12 12 0 0 1 11.515625 12.484375 A 12 12 0 0 1 8.5273438 0.51953125 z ',
    ]}
    {...props}
  />
)
